import React, { useEffect, useState } from 'react'
import { axiosClient } from 'scripts/api'
import { navigate } from 'gatsby'
import { cookies } from 'scripts/cookies'
import { Loading } from 'presentation/components/Loading'
import Img from 'images/screencapture-auth-locked.png'
import { getSubdomain } from 'scripts/getSubdomainForHost'
import { fullUrl } from 'scripts/fullUrl'
import { env } from 'scripts/env'
import { useTranslation } from 'react-i18next'
import { getTeam } from 'api/getTeam'
import { StringParam, useQueryParam } from 'use-query-params'

export const Root = () => {
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const [appClientKeyQueryParam] = useQueryParam('app_client_key', StringParam)

  useEffect(() => {
    const subdomain = getSubdomain(window.location.host)
    const sessionToken = cookies.get('session_token')
    const cookieSubdomain = cookies.get('_locked_auth_subdomain')

    if (subdomain === '' && typeof sessionToken === 'undefined') {
      if (cookieSubdomain) {
        const url = `${env.SELF_PROTCOL}://${cookieSubdomain}.${env.SELF_DOMAIN}:${env.SELF_PORT}`
        window.location.replace(url)
        return
      } else {
        setLoading(false)
        return
      }
    }

    ;(async () => {
      const postUrl = `${fullUrl(
        'api',
      )}/enduser_device_sessions/restore_subdomain`
      const params = { session_token: sessionToken }
      const { data } = await axiosClient.post(postUrl, params)
      const isValidSessionToken = typeof data.subdomain === 'string'

      if (subdomain === '' && !isValidSessionToken) {
        if (cookieSubdomain) {
          const url = `${env.SELF_PROTCOL}://${cookieSubdomain}.${env.SELF_DOMAIN}:${env.SELF_PORT}`
          window.location.replace(url)
          return
        } else {
          setLoading(false)
          return
        }
      }

      const getTeamIdResponse = await getTeam(subdomain)
      const isValidSubdomain =
        getTeamIdResponse.status === 200 &&
        typeof getTeamIdResponse.data.team.id === 'number'

      if (isValidSubdomain && isValidSessionToken) {
        if (appClientKeyQueryParam && appClientKeyQueryParam !== '') {
          navigate(`/apps?app_client_key=${appClientKeyQueryParam}`, {
            replace: true,
          })
          return
        } else {
          navigate('/apps', { replace: true })
          return
        }
      } else if (isValidSubdomain && !isValidSessionToken) {
        if (appClientKeyQueryParam && appClientKeyQueryParam !== '') {
          navigate(`/login?app_client_key=${appClientKeyQueryParam}`, {
            replace: true,
          })
          return
        } else {
          navigate('/login', { replace: true })
          return
        }
      } else if (!isValidSubdomain && isValidSessionToken) {
        const url = `${env.SELF_PROTCOL}://${data.subdomain}.${env.SELF_DOMAIN}:${env.SELF_PORT}/apps`
        window.location.replace(url)
        return
      } else if (!isValidSubdomain && !isValidSessionToken) {
        navigate('/404', { replace: true })
        return
      } else {
        console.info('none of the above')
      }
    })()
  }, [])

  if (loading) {
    return (
      <main
        data-test="loadingRootPage"
        className="pb-20 pt-10 w-full flex justify-center"
      >
        <Loading />
      </main>
    )
  }

  return (
    <div
      className="flex items-center justify-center flex-col"
      data-test="pleaseLoginYourLoginPageLayout"
    >
      <p className="mt-32 text-locked font-bold text-2xl">
        {t('チームごとのログインURLからログインして下さい')}{' '}
      </p>
      <p className="mt-3 mb-10">
        {t('LOCKEDにログインすると、機能を使えるようになります。')}{' '}
      </p>
      <img src={Img} className="w-48" />
      <button className="mt-10 rounded h-12 bg-gray-200 px-4">
        <div className="flex justify-center items-center">
          <p className="mr-3 text-gray-700">{t('よくある質問')} </p>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.00094 0L0.585938 1.415L5.17094 6L0.585938 10.585L2.00094 12L8.00094 6L2.00094 0Z"
              fill="#828282"
            ></path>
          </svg>
        </div>
      </button>
    </div>
  )
}
