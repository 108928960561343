import { GetTeamResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const getTeam = async (subdomain: string) => {
  const res = await axiosClient.get<GetTeamResponse>(
    `${fullUrl()}/login`,
    { params: { subdomain: subdomain } }, // paramsの渡し方を修正したけど、そもそもapi側ではurlの方のsubdomainをみてるのでparamsにはいらない
  )
  return res
}
